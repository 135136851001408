function scrollTo(element, speed, offset){
  if (!isset(speed, 'number')) {
    speed = 700;
  }

  if (!isset(offset, 'number')) {
    offset = 0;
  }

  $('html, body').animate({
    scrollTop: ($(element).offset().top - offset)
  }, speed, 'swing');
};