$(function() {
  var menu = $('nav.obis-site-mainmenu');

  menu.find('.major-menu-item').on('mouseenter mouseleave', function (event) {
    var submenu = $(this).children('ul.submenu');
    var transDuration = (submenu.children().length * 100) + 50;

    submenu.stop();

    if (event.type === 'mouseenter') {
      submenu.slideDown(transDuration);
    } else {
      submenu.slideUp(transDuration);
    }
  });
})