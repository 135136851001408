// see http://scrollmagic.io/examples/basic/custom_actions.html 

if (isset($, 'function') && isset($.easing, 'object')) {
  // custom cubic bezier easing function
  $.easing.easeOutCubic = function(x, t, b, c, d) {
    var ts=(t/=d)*t;
    var tc=ts*t;
    return b+c*(tc + -3*ts + 3*t);
  }
}

$(function () {
  var controller = new ScrollMagic.Controller();
  
  $('.obis-site-icon-counter').each(function (index, element) {
    var counterComponent = $(element).children('.counter-wrapper').children('.counter-component').get(0);
    var scene = new ScrollMagic.Scene({
      offset: ($(element).offset().top - ($(window).innerHeight() * 0.7)),
      duration: $(element).outerHeight(),
    }).addTo(controller);

    // Set state object on counter component element
    counterComponent._state = {
      done: false,
      end: null,
      from: $(counterComponent).data('number-range-start'),
      running: false,
      scene: scene,
      start: null,
      to: $(counterComponent).data('number-range-end'),
    };

    // Strip configuration values from markup
    $(counterComponent).removeAttr('data-number-range-start');
    $(counterComponent).removeAttr('data-number-range-end');
  
    // Set event listener for triggering the counter
    scene.on('start end', function (e) {
      // Start animation only if it is the first forward scroll into view
      if (e.scrollDirection !== 'FORWARD' || counterComponent._state.done || counterComponent._state.running) {
        return;
      }
      
      $(counterComponent).prop('counter', $(counterComponent).data('number-range-start')).animate({
        counter: $(counterComponent).data('number-range-end')
      }, {
        duration: 4000,
        easing: 'easeOutCubic',
        step: function (now) {
          $(counterComponent).text(Math.ceil(now));
        },
        start: function() {
          counterComponent._state.running = true;
          counterComponent._state.start = Date.now();
        },
        done: function() {
          counterComponent._state.done = true;
          counterComponent._state.end = Date.now();
          counterComponent._state.running = false;
        }
      });
    });
  });
});
